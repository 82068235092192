<template>
  <div class="single-bridge-common">
    <div class="tit-search flex space-between">
      <div class="mb-2">
        <el-button class="btn-primary" @click="goto()">新增</el-button>
      </div>
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="pageGet(true)"
      ></c-search>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="类型" prop="type">
                <el-select v-model="form.model.type" placeholder="请选择">
                  <el-option label="结构构件" :value="0"></el-option>
                  <el-option label="结构整体" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="传感器" prop="sensorId">
                <el-select v-model="form.model.sensorId" placeholder="请选择" @change="getSensorDetail">
                  <el-option v-for="(sensor,s) in sensorList" :key="s" :label="sensor.name" :value="sensor.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.model.sensorId">
              <el-form-item label="测点" prop="sensorDetailId">
                <el-select v-model="form.model.sensorDetailId" placeholder="请选择">
                  <el-option v-for="(item,i) in sensorDetailList" :key="i" :label="item.subName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "/health/assess/config/listPage",
    pageDelPath: "/health/assess/config/remove",
    pageDelsPath: "",
    pageParams: {},
  });
  export default {
    mixins: [mPage],
    data() {
      let { searchItems,tableColumns } = Configs(this);
      return {
        searchItems,
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[],
        form:{
          model:{},
          rules:{
            type:[
              { required: true, message: '请选择类型', trigger: 'blur' }
            ],
            sensorId:[
              { required: true, message: '请选择传感器', trigger: 'blur' }
            ],
            sensorDetailId:[
              { required: true, message: '请选择测点', trigger: 'blur' }
            ]
          }
        },
        sensorList:[],
        sensorDetailList:[],
        isAdd:true
      };

    },
    created() {
      this.getSensor()
    },
    methods: {
      getSensor(){
        this.$http.get("/sensorAnalysis/sensorType/list").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = res.data
              this.searchItems[1].options = []
              for (let item of this.sensorList){
                this.searchItems[1].options.push({label: item.name, value: item.id})
              }
            }
          }
        })
      },
      getSensorDetail(){
        this.$http.get("/sensorAnalysis/sensorDetail/list",{params:{id:this.form.model.sensorId}}).then(res => {
          if(res.success){
            if (res.data){
              this.sensorDetailList = res.data
            }
          }
        })
      },
      goto() {
        this.form.model = {
          type:'',
          sensorId:'',
          sensorDetailId:''
        }
        this.title = "新增结构信息";
        this.isAdd = true
        this.dialogVisible = true;
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post("/health/assess/config/save",this.form.model).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.pageGet(true)
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
</style>