import store from '@/store/index.js'
let dic = store.state.dic;
export default function Configs(that) {
  const searchItems = [
    {
      label: "类型",
      span: 12,
      model: "type",
      clearable: true,
      type: "select",
      options: [
        {label:'结构构件',value:0},
        {label:'结构整体',value:1}
      ]
    },
    {
      label: "传感器",
      span:12,
      type: "select",
      model: "sensorId",
      options: [],
      clearable: true,
    },
  ];
  const tableColumns = [
    {
      label: "类型",
      prop: "typeName",
      align: "center",
      show: true
    },
    {
      label: "传感器",
      prop: "sensorName",
      align: "center",
      show: true
    },
    {
      label: "测点",
      prop: "sensorDetailName",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
